import auth, { AuthState } from "./auth";
import users, { UsersState } from "./users";

export interface ApplicationState {
  auth: AuthState;
  users: UsersState;
}

const rootReducer = {
  auth,
  users,
};

export default rootReducer;
