import React, { Component } from 'react';
import { Segment, Button, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { updateFetchState, updateAuthError } from '../../reducers/auth';
import Logo from '../../components/Logo/Logo';
import styles from './Login.module.scss';
import Auth from '../../lib/Auth';
import { ApplicationState } from '../../reducers';

interface LoginProps {
  updateFetchState: (state: boolean) => void;
  updateAuthError: (state: boolean | {}) => void;
  authError: boolean | {};
  isFetching: boolean;
}

class Login extends Component<LoginProps> {
  signIn = async () => {
    try {
      await Auth.signIn();
    } catch (e) {
      this.props.updateAuthError(e);
      this.props.updateFetchState(false);
    } finally {
    }
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <Segment className={styles.login}>
          <div className={styles.logoWrapper}><Logo height={64} /></div>
          <h1 className={styles.appName}>SDMS</h1>
          {this.props.authError ? (
            <Message warning>
              <Message.Header>Problem logging in</Message.Header>
              Something went wrong, try again.
            </Message>
          ) : null}
          <p>Login with your Roche GMail account</p>
          <div className={styles.button}>
            <Button primary onClick={this.signIn} disabled={this.props.isFetching}>
              Login
            </Button>
          </div>
        </Segment>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => ({ authError: state.auth.authError, isFetching: state.auth.isFetching }),
  { updateFetchState, updateAuthError }
)(Login);

