import store from '../store';
import api from '../api/api';
import localforage from 'localforage';
import { fetchUserUsers, storeUsers } from '../reducers/users';
import { ApiUserObject } from '../api/admin/users';

export default function prefetch() {
  store.dispatch(fetchUserUsers(api.listUsers()));
}

export function restoreFromLocal() {
  localforage.getItem('users').then((res) => {
    const users = res as { [key: number]: ApiUserObject };
    if (users) {
      store.dispatch(storeUsers(Object.values(users)));
    }
  })
}

export function subscribeToStore() {
  localforage.config({
    name: 'sdms-storage',
  });
  restoreFromLocal();
  
  let unsubscribe = store.subscribe(() => {
    if (store.getState().auth.isLoggedIn) {
      unsubscribe();
      prefetch();
    }
  });
}
