import React, { Component, ReactNode } from "react";
import {
  Menu,
  Icon,
  Input,
  Popup,
  Button,
  List,
  Dropdown
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import { ApplicationState } from "../../reducers";
import styles from "./Dashboard.module.scss";
import { logout } from "../../reducers/auth";
import Auth from "../../lib/Auth";
import { keys } from "lodash";
import Logo from "../Logo/Logo";

export interface MenuItem {
  label: string;
  to: string;
}

export interface DashboardProps {
  menuItems: { [key: string]: MenuItem[] };
  authName: string;
  authAvatar: string;
  children: ReactNode;
  history: any;
  logout: () => void;
  openAction: number,
}

interface DashboardState {
  visible: boolean;
  isLoading: boolean;
  users: any;
};

class Dashboard extends Component<DashboardProps, DashboardState> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      users: [],
      isLoading: false,
    };
  }

  componentWillReceiveProps = (props: any) => {
  }


  toggleNav = () => {
    let visible = this.state.visible;
    this.setState({
      visible: !visible
    });
  };

  renderMenu() {
    const url = window.location.pathname;
    const urlParts = url.split("/");
    let active = "";
    if (urlParts.length === 1) active = "/";
    else active = urlParts[1];

    const menu = keys(this.props.menuItems).map(key => {
      const menuItems = this.props.menuItems[key];

      const elems = menuItems.map(o => (
        <MenuItem
          close={() => this.setState({ visible: false })}
          key={o.label + o.to}
          name={o.label}
          to={o.to}
          active={o.to === `/${active}`}
        />
      ));

      return elems;

      const keyName = key === 'general' ? '' : key;

      return (
        <Menu.Item key={key}>
          <Menu.Header>{keyName}</Menu.Header>
          <Menu.Menu>
            {elems}
          </Menu.Menu>
        </Menu.Item>
      );
    });

    return menu;
  }

  render() {
    let { visible } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.head}>
          <div className={styles.topLeft}>
            <button className={styles.burger}>
              <Icon onClick={this.toggleNav} size="large" name="bars" />
            </button>
            <Logo className={styles.logo} />
          </div>
          <div className={styles.search}>
          <Menu secondary className={styles.menu}>
              {this.renderMenu()}
          </Menu>
          </div>
          <div className={styles.userActions}>
            <Popup
              trigger={
                <div className={styles.user}>
                  <img
                    style={{ cursor: "pointer" }}
                    src={this.props.authAvatar}
                    alt={this.props.authName}
                    className={styles.avatar}
                  />
                </div>
              }
              on="click"
              content={() => (
                <div className={styles.profilePopup}>
                  <Link className={styles.profileLink} to="/profile">
                    Bekijk profiel
                  </Link>

                  <Button onClick={Auth.signOut} fluid>
                    Uitloggen
                  </Button>
                </div>
              )}
              position="bottom right"
            />
          </div>
        </div>
        {/*<div className={styles.navigationWrapper}>
          <div
            className={classNames({
              [styles.navigation]: true,
              [styles.visible]: visible
            })}
          >
            <div className={styles.menuWrapper}>
              <Menu vertical className={styles.menu}>
                {this.renderMenu()}
              </Menu>
            </div>
          </div>
          </div>*/}
        <div className={styles.content}>{this.props.children}</div>
        {window.location.href.indexOf('training') !== -1 && <><div className="fwarn fwarn--staging">!!! - TRAINING - !!!</div><div style={{height: 70}} /></>}
        {window.location.href.indexOf('localhost') !== -1 && <><div className="fwarn fwarn--dev">!!! - LOCAL - !!!</div><div style={{height: 70}} /></>}
      </div>
    );
  }
}

function MenuItem(props: any) {
  return (
    <Link onClick={props.close} to={props.to}>
      <Menu.Item name={props.name} active={props.active} />
    </Link>
  );
}

function mapStateToProps(state: ApplicationState, ownProps: any) {
  return {
    authName: state.auth.name,
    authAvatar: state.auth.avatar,
  };
}

const mapDispatchToProps = {
  logout,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Dashboard));
