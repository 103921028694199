import { generateRest } from "../utils";

// API Object type definitions
export interface ApiRoleObject {
  id: number;
  name: string;
  display_name: string;
}

export type ApiRole = ApiRoleObject | number;

export interface ApiUserObject {
  id: number;
  first_name: string;
  last_name: string;
  full_name?: string;
  email_address: string;
  avatar: string | null;
  roles?: ApiRole[];
  server_roles?: ApiRoleObject[];
  type?: string;
  vacation_mode?: boolean,
  vacation_replacement?: ApiUserObject,
}

const rest = generateRest<ApiUserObject>('admin/users', ['roles', 'squads', 'chapters']);

export default {
  ...rest,
  list: (q: string = '', whitelisted: boolean = false, page = 0, qWith = ['roles', 'squad']) => {
    return rest.list(q, qWith, {
      whitelisted: whitelisted ? 1 : 0,
      page,
    });
  }
}
