import React from 'react';
import logo from './roche-logo-m.svg';

interface LogoProps {
  height: number;
}

export default function Logo({ height = 36, ...props }) {
  const imageHeight = 103.81615;
  const imageWidth = 200;
  const ratio = imageWidth / imageHeight;
  const width = height * ratio;

  const padding = height / 2;
  const wrapperHeight = padding + height + padding;

  return (
    <div style={{ padding, height: wrapperHeight }} {...props}>
      <img style={{ width, height }} src={logo} alt="Roche Logo" />
    </div>
  )

}