import qs from "qs";
import { ApiResponse } from "./api";
import { get } from "./utils";
import { ApiFormPageObject } from "./applications";

export interface ApiFormQuestionObject {
  id: number,
  form_id: number,
  step: number,
  short_code?: string,
  form?: ApiFormObject,
  position: number,
  type: string,
  content: string,
  description?: string,
  options?: [{
    id: number,
    label: string,
  }],
  skip: [number, any[]],
  is_required: boolean,
  placeholder: string,
}

export interface ApiFormObject {
  id: number;
  name: string;
  description?: string;
  questions: ApiFormQuestionObject[],
  pages: ApiFormPageObject[],
}

export default {
  show: (id: number, admin: boolean = false, qWith?: string[], props?: {}) => {
    const defaultWith = qWith || ['questions'];
    const query = qs.stringify({
      with: defaultWith.join(','),
      ...props,
    });
    return get(`${admin === true ? 'admin/' : ''}forms/${id}?${query}`) as ApiResponse<ApiFormObject>;
  }
}
