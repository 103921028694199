import { generateRest, post, get } from "../utils";
import { ApiResponse } from "../api";
import { ApiArticleObject } from "./articles";

export interface ApiTemplateObject {
  id: number;
  name: string;
  content?: string;
  articles: ApiArticleObject[] | number[];
  selection?: number[];
  status: number,
}

const rest =  generateRest<ApiTemplateObject>('admin/templates');

export default {
  ...rest,
  duplicate: (templateId: number) => post(`admin/templates/${templateId}/duplicate`) as ApiResponse<ApiTemplateObject>,
  placeholders: (templateId: number, applicationId: number) => get(`templates/${templateId}/placeholders/${applicationId}`) as ApiResponse<ApiTemplateObject>,
}
