import { createReducer } from 'redux-starter-kit';
import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { pickBy } from 'lodash';
import { ApiUserObject } from '../api/admin/users';
import { ApiResponse, ApiList } from '../api/api';
import localforage from 'localforage';

export interface UsersState {
  users: { [key: number]: ApiUserObject },
  whitelist: { [key: number]: ApiUserObject },
  isUsersFetching: boolean;
  isWhitelistFetching: boolean;
  hasError: boolean;
  error: any;
};

// Action creators
export const storeUsers = createAction<ApiUserObject[]>('storeUsers');
export const storeWhitelist = createAction<ApiUserObject[]>('storeWhitelist');
export const resetUsersError = createAction('resetUsersError');
export const setUsersError = createAction<any>('setUsersError', null, () => ({ isError: true }));
export const filterUser = createAction<number>('filterUser');
export const setUsersFetchingState = createAction<boolean>('setUsersFetchingState');
export const setWhitelistFetchingState = createAction<boolean>('setWhitelistFetchingState');

// Async action creators
export function fetchUsers(request: ApiResponse<ApiList<ApiUserObject>>) {
  return (dispatch: Dispatch) => {
    dispatch(setUsersFetchingState(true));
    return request
      .then(res => dispatch(storeUsers(res.data)))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setUsersFetchingState(false)));
  }
}

export function fetchWhitelist(request: ApiResponse<ApiList<ApiUserObject>>) {
  return (dispatch: Dispatch) => {
    dispatch(setWhitelistFetchingState(true));
    return request
      .then(res => dispatch(storeWhitelist(res.data)))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setWhitelistFetchingState(false)));
  }
}

export function updateUser(request: ApiResponse<ApiUserObject>) {
  return (dispatch: Dispatch) => {
    dispatch(setUsersFetchingState(true));
    return request
      .then(res => dispatch(storeUsers([res])))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setUsersFetchingState(false)));
  };
}

export function updateWhitelistUser(request: ApiResponse<ApiUserObject>) {
  return (dispatch: Dispatch) => {
    dispatch(setWhitelistFetchingState(true));
    return request
      .then(res => dispatch(storeWhitelist([res])))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setWhitelistFetchingState(false)));
  }
}

interface DeleteUserProps {
  id: number,
  request: ApiResponse<{}>,
};
export function deleteUser({ id, request }: DeleteUserProps) {
  return (dispatch: Dispatch) => {
    dispatch(setUsersFetchingState(true));
    return request
      .then(res => dispatch(filterUser(id)))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setUsersFetchingState(false)))
  }
}

export function fetchUserUsers(request: ApiResponse<ApiUserObject[]>) {
  return (dispatch: Dispatch) => {
    dispatch(setUsersFetchingState(true));
    return request
      .then(res => dispatch(storeUsers(res)))
      .catch((error: Error) => dispatch(setUsersError(error.message)))
      .finally(() => dispatch(setUsersFetchingState(false)));
  }
}

const usersReducer = createReducer<UsersState>({
  users: {},
  whitelist: {},
  isUsersFetching: false,
  isWhitelistFetching: false,
  hasError: false,
  error: null,
}, {
  [storeUsers.toString()]: (state, action) => {
    const users: ApiUserObject[] = action.payload;
    // Map each new user to the current state users. The ID will be used for key.
    users.forEach(user => {
      state.users[user.id] = user;
    });

    localforage.setItem('users', users);
  },

  [filterUser.toString()]: (state, action) => {
    // A user can be present in the whitelist or the users list. This will filter them from both.
    state.users = pickBy<ApiUserObject>(state.users, (v: ApiUserObject, k: string) => {
      return parseInt(k) !== action.payload;
    });
    state.whitelist = pickBy<ApiUserObject>(state.whitelist, (v: ApiUserObject, k: string) => {
      return parseInt(k) !== action.payload;
    });
  },

  [setUsersError.toString()]: (state, action) => {
    state.hasError = true;

    // Try parsing the error message, it might be json.
    try {
      state.error = JSON.parse(action.payload)
    } catch(e) {
      state.error = action.payload;
    }
  },

  [resetUsersError.toString()]: (state) => {
    state.hasError = false;
    state.error = undefined;
  },

  [storeWhitelist.toString()]: (state, action) => {
    const whitelist: ApiUserObject[] = action.payload;
    whitelist.forEach(user => {
      state.whitelist[user.id] = user;
    });
  },

  [setUsersFetchingState.toString()]: (state, action) => {
    // When something changes with the user, the error is no longer valid.
    if (action.payload) {
      state.hasError = false;
      state.error = undefined;
    }
    state.isUsersFetching = action.payload;
  },
  
  [setWhitelistFetchingState.toString()]: (state, action) => {
    // When something changes with the whitelist, the error is no longer valid.
    if (action.payload) {
      state.hasError = false;
      state.error = undefined;
    }
    state.isWhitelistFetching = action.payload;
  },
});

export default usersReducer;