import admin from './admin';
import users from './users';
import auth from './auth';
import forms from './forms';
import { get, post, patch, put, del } from './utils';
import applications from './applications';

export type HttpTypes = 'GET' | 'HEAD' | 'POST' | 'PUT' | 'DELETE' | 'CONNECT' | 'OPTIONS' | 'TRACE' | 'PATCH';

export interface ApiResponseObject {
  status?: 'ok';
  code?: number;
  errors?: {
    [key: string]: string;
  };
}

export type ApiResponse<T> = Promise<T & ApiResponseObject>;

export interface ApiList<T> {
  data: T[];
  links: {
    first: string;
    last: string;
    prev?: string;
    next?: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    path: string;
    per_page: number;
    to: number;
    total: number;
  }
}

const api = {
  get,
  post,
  patch,
  put,
  del,

  admin: {
    destroyArticle: admin.articles.destroy,
    listArticles: admin.articles.list,
    showArticle: admin.articles.show,
    storeArticle: admin.articles.store,
    updateArticle: admin.articles.update,
    putArticle: admin.articles.put,
    destroyTemplate: admin.templates.destroy,
    listTemplates: admin.templates.list,
    showTemplate: admin.templates.show,
    storeTemplate: admin.templates.store,
    updateTemplate: admin.templates.update,
    getPlaceholders: admin.templates.placeholders,
    duplicateTemplate: admin.templates.duplicate,
    putTemplate: admin.templates.put,
    destroyUser: admin.users.destroy,
    listUsers: admin.users.list,
    showUser: admin.users.show,
    storeUser: admin.users.store,
    updateUser: admin.users.update,
    putUser: admin.users.put,
  },
  listUsers: users.list,
  showAuthProfile: auth.profile,
  saveVacation: auth.saveVacation,
  showForm: forms.show,
  archiveApplication: applications.archive,
  addApplicationViewer: applications.addViewer,
  removeApplicationViewer: applications.removeViewer,
  showApplication: applications.show,
  createApplication: applications.create,
  deleteApplication: applications.destroy,
  editApplication: applications.edit,
  duplicateApplication: applications.duplicate,
  startApplication: applications.start,
  saveApplicationAnswers: applications.saveAnswers,
  saveApplicationTemplate: applications.saveTemplate,
  uploadApplicationFiles: applications.upload,
  deleteApplicationFile: applications.deleteFile,
  completeApplication: applications.complete,
  listApplications: applications.list,
  approveApplication: applications.approve,
  rejectApplication: applications.reject,
  setApplicationApprovers: applications.setApprovers,
  setApplicationDrafter: applications.setDrafter,
  requestApplicationInformation: applications.requestInformation,
  patchApplication: applications.update,
  replaceArticleContent: applications.replaceArticleContent,
  sendApplicationRequest: applications.sendRequest,
  applicationAddNote: applications.addNote,
  resetApplication: applications.resetApplication,
};

export default api;
