import config from '../config.json';
import store from '../store';
import { logout, login, updateProfile, updateRoles, setProfile, updateFetchState } from '../reducers/auth';
import { mapObjectToId } from './objectToId';
import api from '../api/api';
import { ApiRole, ApiRoleObject } from '../api/admin/users.js';

export function init() {
  window.removeEventListener('mwitGapiLoaded', init);
  if ((window as any).gapi) {
    // Window has a gapi object, start using it.
    gapi.load('auth2', onAuth2Load);
  } else {
    // Window does not have a gapi object, listen for it to load. This event is thrown in index.html.
    // `<script>function onGapiLoaded() { window.dispatchEvent(new Event('mwitGapiLoaded')); }</script>`
    window.addEventListener('mwitGapiLoaded', init);
  }
}

async function onSignedInChange() {
  const authInstance = gapi.auth2.getAuthInstance();
  const isSignedIn = authInstance.isSignedIn.get();
  if (isSignedIn) {
    const user = authInstance.currentUser.get();
    const authResponse = user.getAuthResponse();
    const profile = user.getBasicProfile();

    store.dispatch(login(authResponse));
    store.dispatch(updateProfile({
      name: profile.getName(),
      email: profile.getEmail(),
      avatar: profile.getImageUrl(),
    }));

    const masterboardProfile = await api.showAuthProfile();//@TODO: waarom geen user id?
    store.dispatch(updateRoles(mapObjectToId(masterboardProfile.roles || [])));
    store.dispatch(setProfile(masterboardProfile));
    store.dispatch(updateFetchState(false));
  } else {
    store.dispatch(logout());
  }
}

async function onAuth2Load() {
  await gapi.auth2.init({
    client_id: config.googleClientID,
  }).then((e: any) => console.log('Google Auth', e))
    .catch((e: any) => console.error(e));

  // Check if the user is already signed-in.
  onSignedInChange();
  // Also listen for changes, it might still be unknown if the user is logged in.
  gapi.auth2.getAuthInstance().isSignedIn.listen(onSignedInChange);
}

export function signIn() {
  store.dispatch(updateFetchState(true));
  return gapi.auth2.getAuthInstance().signIn();
}

export function signOut() {
  gapi.auth2.getAuthInstance().signOut();
  window.location.href = '/';
  return;
}

export function hasRole(check: number[], roles: ApiRoleObject[]) {
  let ret = false;
  
  check.map(o => {
    roles.map((r) => {
      if(r.id === o) {
        ret = true;
      }
    });
  });

  return ret;
}

export default {
  init,
  signIn,
  signOut,
}
