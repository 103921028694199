import React from 'react';
import { UserRole } from './lib/roles';
import { includes } from 'lodash';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory, History } from 'history';
import { connect } from 'react-redux';
import { ApplicationState } from './reducers';
import Splash from './screens/Splash/Splash';
import Login from './screens/Login/Login';
import LoadComponent from './components/LoadComponent/LoadComponent';
import Dashboard, { MenuItem } from './components/Layout/Dashboard';
import objectToId, { mapObjectToId } from './lib/objectToId';
import { ApiRole } from './api/admin/users';
import { hasRole } from './lib/Auth';
import { ApiProfileObject } from './api/auth';

// Setup history
export const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => callback(window.confirm(message))
});

let unblockCallback: any;

export function blockRouter(message: string = 'have have entered some data, are you sure you want to leave the page?') {
  if (unblockCallback) {
    unblockCallback();
  }
  unblockCallback = history.block(message);
}

export function unblockRouter() {
  if (unblockCallback) {
    unblockCallback();
    unblockCallback = undefined;
  }
}

export function isRouterBlocked() {
  return !!unblockCallback;
}

export function setRouterBlock(b: boolean, message: string) {
  if (b) {
    blockRouter(message);
  } else {
    unblockRouter();
  }
}

history.listen(e => {
  unblockRouter();
})

declare global {
  interface Window {
    browserHistory: History
  }
}

window.browserHistory = history;

// Async loaded screens
const AsyncForm = () => <LoadComponent loader={() => import('./screens/Form/Form')} />;

const AsyncHome = () => <LoadComponent loader={() => import('./screens/Home/Home')} />;
const AsyncDebug = () => <LoadComponent loader={() => import('./screens/Debug/Debug')} />;

const AsyncArticles = () => <LoadComponent loader={() => import('./screens/Articles/Articles')} />;
const AsyncArticleEdit = () => <LoadComponent loader={() => import('./screens/Articles/ArticleEdit')} />;
const AsyncTemplates = () => <LoadComponent loader={() => import('./screens/Templates/Templates')} />;
const AsyncTemplateEdit = () => <LoadComponent loader={() => import('./screens/Templates/TemplateEdit')} />;
const AsyncUsers = () => <LoadComponent loader={() => import('./screens/Users/Users')} />;
const AsyncUserEdit = () => <LoadComponent loader={() => import('./screens/Users/UserEdit')} />;

const AsyncApplications = () => <LoadComponent loader={() => import('./screens/Applications/Applications')} />;
const AsyncApplicationShow = () => <LoadComponent loader={() => import('./screens/Applications/Show')} />;

const AsyncProfile = () => <LoadComponent loader={() => import('./screens/Profile/Profile')} />;


function checkRole(roles: ApiRole[], role: UserRole) {
  return includes(mapObjectToId(roles), role);
}

interface RootRouterProps {
  profile?: ApiProfileObject;
  isAuthFetching: boolean;
};

interface Menu {
  [key: string]: MenuItem[]
};

function RootRouter(props: RootRouterProps) {
  const { profile, isAuthFetching } = props;
  if (!profile) {
    // If the user is not logged in
    return (
      <Router history={history}>
        <div>
          <Splash active={isAuthFetching} />
          <Route exact path="/aanvraag/:type" component={AsyncForm} />
          <Route exact path="/" component={Login} />
        </div>
      </Router>
    );
  }

  const routes = [
    <Route key="home" exact path="/" component={AsyncApplications} />
  ];
  const menuItems: Menu = {
    // 'general': [
    //   {
    //     label: 'Home',
    //     to: '/',
    //   }
    // ]
  };

  if (profile.server_roles && hasRole([1], profile.server_roles)) {
    // routes.push(<Route key="debug" path="/debug" component={AsyncDebug} />);
    routes.push(<Route exact key="articles" path="/articles" component={AsyncArticles} />);
    routes.push(<Route key="articleEdit" path="/articles/:id/edit" component={AsyncArticleEdit} />);
    routes.push(<Route exact key="articleCreate" path="/articles/create" component={AsyncArticleEdit} />);
    routes.push(<Route exact key="templates" path="/templates" component={AsyncTemplates} />);
    routes.push(<Route key="templateEdit" path="/templates/:id/edit" component={AsyncTemplateEdit} />);
    routes.push(<Route exact key="templateCreate" path="/templates/create" component={AsyncTemplateEdit} />);
    routes.push(<Route exact key="users" path="/users" component={AsyncUsers} />);
    routes.push(<Route key="userEdit" path="/users/:id/edit" component={AsyncUserEdit} />);

    menuItems['Admin'] = [
      {
        label: 'Articles',
        to: '/articles',
      },
      {
        label: 'Templates',
        to: '/templates',
      },
      {
        label: 'Users',
        to: '/users',
      },
      // {
      //   label: 'Debug',
      //   to: '/debug',
      // },
    ];
  }

  routes.push(<Route exact key="applications" path="/applications" component={AsyncApplications} />);
  routes.push(<Route key="applicationShow" path="/applications/:id" component={AsyncApplicationShow} />);
  routes.push(<Route key="profile" path="/profile" component={AsyncProfile} />);

  menuItems['SDMS'] = [
    {
      label: 'Applications',
      to: '/applications',
    },
    {
      label: 'Profile',
      to: '/profile',
    },
  ];

  return (
    <Router history={history}>
      <Dashboard menuItems={menuItems}>
        {routes}
      </Dashboard>
    </Router>
  );
}

export default connect((state: ApplicationState) => ({
  profile: state.auth.profile,
  isAuthFetching: state.auth.isFetching,
}))(RootRouter);
