import qs from "qs";
import { ApiResponse } from "./api";
import { del, get, post, generateRest, upload, request } from "./utils";
import { ApiFormObject, ApiFormQuestionObject } from "./forms";
import { ApiUserObject } from "./admin/users";
import { ApiTemplateObject } from "./admin/templates";

export interface ApiFormPageObject {
  id: number,
  name: string,
  content?: string,
  position: number,
  questions: ApiFormQuestionObject[],
}

export interface ApiApplicationArticleObject {
  id: number;
  article_id: number;
  content: string;
  orignal_content: string;
  position: number;
}

export interface ApiApplicationApprovalObject {
  id: number,
  type: string,
  message?: string,
  file?: string,
  filename?: string,
  created_at: string,
  user: ApiUserObject,
}

export interface ApiApplicationAnswerObject {
  id?: any;
  application_id?: number;
  form_question_id: number;
  answer: string;
  admin_answer?: string;
  file?: string;
}

export interface ApiApplicationObject {
  id: number;
  reference: number;
  title?: string;
  hash_id: string;
  form_id: number;
  form?: ApiFormObject;
  form_question_id: number;
  current_question?: ApiFormQuestionObject;
  answers: ApiApplicationAnswerObject[],
  form_page_id?: number,
  page?: ApiFormObject,
  created_at: string,
  updated_at: string,
  completed_at?: string,
  approved_at?: string,
  accepted_at?: string,
  user_id?: number,
  user: ApiUserObject,
  created_by_id?: number,
  created_by?: ApiUserObject,
  status: number,
  status_name: string,
  name?: string,
  initials?: string,
  email_address?: string,
  telephone_number?: string,
  function?: string,
  approvals?: ApiApplicationApprovalObject[],
  newApprovals?: ApiApplicationApprovalObject[],
  template_id?: number,
  template?: ApiTemplateObject, 
  articles?: ApiApplicationArticleObject,
  approvers?: ApiUserObject[],
  viewers?: ApiUserObject[],
  url: string,
  files?: any,
  grace_id?: string,
  last_signed_at?: string,
  archiver_id?: number,
}

const rest = generateRest<ApiApplicationObject>('applications', ['form']);

export default {
  ...rest,
  start: (formId: number, data: any) => {
    return post(`forms/${formId}/start-application`, data) as ApiResponse<ApiApplicationObject>;
  },
  edit: (id: number, qWith?: string[], props?: {}) => {
    const defaultWith = qWith || ['answers', 'user'];
    const query = qs.stringify({
      with: defaultWith.join(','),
      ...props,
    });
    return get(`applications/${id}/show?${query}`) as ApiResponse<ApiApplicationObject>;
  },
  show: (hashId: string, qWith?: string[], props?: {}) => {
    const defaultWith = qWith || ['answers'];
    const query = qs.stringify({
      with: defaultWith.join(','),
      ...props,
    });
    return get(`applications/${hashId}?${query}`) as ApiResponse<ApiApplicationObject>;
  },
  saveAnswers: (application: ApiApplicationObject, currentPage?: ApiFormPageObject, a?: number) => {
    const data: any = {
      answers: application.answers,
      name: application.name,
      email_address: application.email_address,
      telephone_number: application.telephone_number,
      a,
    };

    if (currentPage) {
      data.form_page_id = currentPage.id;
    }

    return post(`applications/${application.id}`, data) as ApiResponse<ApiApplicationObject>;
  },
  create: (formId: number) => {
    return post(`applications`, { form_id: formId }) as ApiResponse<ApiApplicationObject>;
  },
  complete: (application: ApiApplicationObject) => {
    return post(`applications/${application.id}/complete`) as ApiResponse<boolean>;
  },
  archive: (application: ApiApplicationObject, status: number = 9) => {
    return post(`applications/${application.id}/archive`, { status }) as ApiResponse<boolean>;
  },
  duplicate: (applicationId: number) => {
    return post(`applications/${applicationId}/duplicate`) as ApiResponse<ApiApplicationObject>;
  },
  upload: (application: ApiApplicationObject, currentQuestion: ApiFormQuestionObject, files: FileList) => {
    Array.from(files).map(async (file) => {
      const data = new FormData();
      data.append('form_question_id', ''+currentQuestion.id);
      await upload(`applications/${application.id}/upload-file`, 'file', file, data);
    });

    return true;
  },
  approve: (applicationId: number, isDrafter: boolean = false) => {
    return post(`applications/${applicationId}/approve`, {
      isDrafter,
    }) as ApiResponse<ApiApplicationObject>;
  },
  reject: (applicationId: number, reason: string, isDrafter: boolean = false, hideReason: boolean = false) => {
    return post(`applications/${applicationId}/reject`, {
      reason,
      isDrafter,
      hideReason,
    }) as ApiResponse<ApiApplicationObject>;
  },
  requestInformation: (applicationId: number, reason: string) => {
    return post(`applications/${applicationId}/request-information`, {
      reason,
    }) as ApiResponse<ApiApplicationObject>;
  },
  saveTemplate: (application: ApiApplicationObject, articles: any) => {
    return post(`applications/${application.id}/save-template`, {
      template_id: application.template_id,
      articles,
    }) as ApiResponse<ApiApplicationObject>;
  },
  replaceArticleContent: (applicationId: number, content: string) => {
    return post(`applications/${applicationId}/replace-content`, {
      content,
    }) as ApiResponse<{content: string}>;
  },
  setApprovers: (applicationId: number, approvers: any[]) => {
    return post(`applications/${applicationId}/set-approvers`, {
      approvers,
    }) as ApiResponse<ApiApplicationObject>;
  },
  setDrafter: (applicationId: number, drafter: number) => {
    return post(`applications/${applicationId}/set-drafter`, {
      drafter,
    }) as ApiResponse<ApiApplicationObject>;
  },
  sendRequest: (applicationId: number, emailAddress: string, reason?: string) => {
    return post(`applications/${applicationId}/send-request`, {
      email_address: emailAddress,
      reason,
    });
  },
  addNote: (applicationId: number, note: string, attachment?: File) => {
    if (attachment) {
      const data = new FormData();
      data.append('file', attachment);
      data.append('note', note);
      
      return request(`applications/${applicationId}/add-note`, 'POST', {
        body: data,
      }, new Headers(), true);
    }

    return post(`applications/${applicationId}/add-note`, { note });
  },
  deleteFile: (applicationId: number, fileId: number) => {
    return del(`applications/${applicationId}/file/${fileId}`);
  },
  resetApplication: (applicationId: number) => {
    return post(`applications/${applicationId}/reset`);
  },
  addViewer: (applicationId: number ,userId: number) => {
    return post(`applications/${applicationId}/add-viewer`, { userId });
  },
  removeViewer: (applicationId: number ,userId: number) => {
    return post(`applications/${applicationId}/remove-viewer`, { userId });
  }
}
